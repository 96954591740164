import React from "react"

import Layout from "../../components/layout"
import SEO from "../../components/seo"

const ProjectHelse = ({ data, location }) => {
  return (
    <Layout>
      <SEO title="Posicionamiento web Drupal y captación de tráfico de pago | asdloop" description="Desarrollamos webs optimizadas para posicionamiento en buscadores. y también podemos ayudarte con tus campañas de Google Ads y Facebook Ads." />
  <section className="jumbotron bg-transparent pb-6" id="header">
    <div className="container">
      <div className="row align-items-center text-center">
        <h1 className="font-weight-bold display-4 pl-0 p-1 col-md-9 offset-md-2 col-sm-12">SEO y SEM.
          <br />Posicionamiento web y Tráfico de pago</h1>
        <p className="lead mb-4 col-md-9 offset-md-2 col-sm-12">Disponemos de un equipo certificado para dar servicios de tráfico de pago y SEO en los proyectos que realizamos.</p>
      </div>
    </div>
  </section>
  <section className=" pb-5 pt-5 bg-light text-left" id="features">
    <div className="container">
      <div className="row text-left">
        <div className="col col-md-9 offset-md-2 col-sm-12">
          <h2>
            <strong className="text-left">Google Ads (Adwords)</strong>
          </h2>
          <p className="lead pt-4">Trabajamos las campañas de Google Ads del siguiente modo.</p>
          <h3 className="pt-4">Configuración inicial de las campañas de Adwords</h3>
          <p className="lead">Configuración de la cuenta, creación inicial de las campañas, grupos de anuncios, palabras clave y anuncios.&nbsp; Análisis de la competencia/sector y la segmentación de los anuncios (geográfica, idiomática, horaria, dispositivos.. etc).</p>
          <h3 className="pt-2">Revisión de objetivos y conversiones en Google Analytics</h3>
          <p className="lead">Nos aseguramos de la correcta configuración de la medición de conversiones en Google Analytics para poder medir la conversión de las distintas fuentes de tráfico y su rendimiento.</p>
          <h3 className="pt-2">Desarrollo de reporting de seguimiento mensual de indicadores tráfico de pago</h3>
          <p className="lead">A través de herramientas de BI desarrollamos informes de seguimiento que leen la información de las distintas fuentes de datos que utilizamos, y nos permitan la creación de un cuadro de mando con los principales indicadores, para que tanto el cliente como nosotros podamos medir la evolución de los mismos, así como la efectividad de las acciones de mejora realizadas.</p>
          <h3 className="pt-2">Seguimiento y optimización mensual de campañas de Adwords</h3>
          <p className="lead pb-5">Optimización de campañas, grupos de anuncios, keywords, copys de anuncios, palabras clave negativas, restricción de IPs, con el objetivo de aumentar el CTR y mejorar el nivel de calidad para reduciendo el coste por click de los anuncios. De este modo reduciremos costes por intenciones de búsqueda que no nos interesen, mejoraremos costes de conversión y reduciremos así el coste de adquisición de clientes.</p>
        </div>
      </div>
      <div className="row text-left">
        <div className="col col-md-9 offset-md-2 col-sm-12">
          <h2>
            <strong className="text-left pt-0">Posicionamiento web (SEO)</strong>
          </h2>
          <p className="lead pt-4">Tanto para proyectos de nueva creación como para migraciones desde otras plataformas ofrecemos un servicio de posicionamiento web que incluye las siguientes acciones.</p>
          <h3 className="pt-4">Auditoría y análisis inicial</h3>
          <p className="lead">Lo primero que realizamos es una auditoría del sitio web para analizar el estado de los principales factores que afectan al posicionamiento web, con el objetivo de detectar los problemas más importantes que deben ser resueltos.</p>
          <ul className="lead">
            <li>Indexación</li>
            <li>Arquitectura web</li>
            <li>SEO on page</li>
            <li>Rendimiento web y servidor</li>
            <li>Enlazado interno</li>
            <li>Linkbuilding</li>
            <li>Posicionamiento actual y competencia</li>
            <li>Keyword research</li>
          </ul>
          <h3 className="pt-2">Plan de mejora</h3>
          <p className="lead">Con los resultados de la auditoría, realizaremos un análisis de la situación inicial para proponer y priorizar las acciones de mejora necesarias, tanto en el plano técnico, como a nivel de contenidos y linkbuilding para conseguir el objetivo de optimización del posicionamiento orgánico de las intenciones de búsqueda que muestre el Keyword Research.</p>
          <h3 className="pt-2">Seguimiento mensual</h3>
          <p className="lead">Mensualmente iremos planificando las acciones a implementar según las prioridades del proyecto, midiendo posteriormente su impacto y proponiendo nuevas acciones en aquellas áreas que sigamos detectando una necesidad de mejora.</p>
          <div className="cita">
            <q>La optimización SEO es un trabajo constante de mejora continúa y medición de los resultados.</q>
          </div>
        </div>
      </div>
    </div>
  </section>

    </Layout>
  )
}

export default ProjectHelse
